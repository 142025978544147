import biglogo from './img/biglogo.png'

import './App.css'
import { initializeApp } from 'firebase/app'
const firebaseConfig = {
  apiKey: 'AIzaSyBtOmjFs7KIafsN2boRwjgQMNMwjVDd6UI',
  authDomain: 'biondo-music.firebaseapp.com',
  projectId: 'biondo-music',
  storageBucket: 'biondo-music.appspot.com',
  messagingSenderId: '960641870905',
  appId: '1:960641870905:web:4737ac06424d18e157b1e8'
}
const app = initializeApp(firebaseConfig)

function App () {
  return (
    <div className='App'>
      <div className='titleBar'>
        <img
          src={biglogo}
          className='logoHeaderSmall'
          alt='Load Bearing Music'
        />
      </div>
      <div className='header'>
        <img src={biglogo} className='logoHeader' alt='Load Bearing Music' />
      </div>
      <div className='section section2' style={{ textAlign: 'center' }}>
        <h1 style={{ fontWeight: '600' }}>
          Load Bearing Music Library is a music resource developed by composers
          Chris Biondo, Mac Squier, and Lenny Williams. The music in the library
          is targeted for use by producers of non-fiction and reality-based
          broadcast television, though it can be licensed and used for any video
          or audio production. The composers have collectively won nine national
          Emmy Awards and received 17 Emmy nominations. They have composed over
          1,500 hours of programming, and their music has been heard on
          virtually every major television network.
        </h1>
      </div>
      <div className='section section4' style={{ textAlign: 'center' }}>
        <span>Please feel free to listen to some samples below.</span>
        <br />
        <br />
        <span>
          If you are interested in licensing, please contact us at &nbsp;
          <a href='mailto:loadbearingmusic@gmail.com'>
            loadbearingmusic@gmail.com
          </a>{' '}
          to preview the entire library.
        </span>
        <br />
        <br />
        <span className="noUnderline">
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://biondomusic.com/'
          >
            CHRIS BIONDO
          </a>{' '}
          |{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='http://hlwmusic.com/'
          >
            LENNY WILLIAMS
          </a>{' '}
          |  <a
            target='_blank'
            rel='noopener noreferrer'
            href='http://loadbearingmusic.com/'
          >MAC SQUIER</a>
        </span>
      </div>

      <div className='section section3'>
        <h1>Samples</h1>
        <iframe src='https://lbm-music.web.app/'></iframe>
      </div>
      <div className='section section1 footer'>
        <br /> <br />
        <span>©2016 Load Bearing Music. All Rights Reserved.</span>
        <br /> <br />
      </div>
    </div>
  )
}

export default App
